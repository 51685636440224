import { configureStore } from "@reduxjs/toolkit";
import chatReducer from "./chatSlice"; // Make sure the path is correct

const store = configureStore({
  reducer: {
    chat: chatReducer, // Ensure "chat" matches the slice name
  },
});

export default store;
