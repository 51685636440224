import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Masonry from "react-masonry-css";
import client from "../../client";

const PortfolioMasonry = () => {
  const [activeCategory, setActiveCategory] = useState("All");
  const [projects, setProjects] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const { slug } = useParams();

  // Define a fixed category order
  const predefinedCategoryOrder = [
    "All",
    "Logo Design",
    "Branding",
    "Website",
    "Digital Marketing",
    "Packaging & Printing",
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const portfolioResponse = await client.getEntries({
          content_type: "portfolio",
          "fields.slug": slug,
        });

        if (portfolioResponse.items.length > 0) {
          const formattedProjects = portfolioResponse.items
            .map((item) => {
              if (item && item.fields) {
                return {
                  id: item.sys.id,
                  title: item.fields.title,
                  image: item.fields.images?.[0]?.fields?.file?.url
                    ? `https:${item.fields.images[0].fields.file.url}`
                    : "",
                  category: Array.isArray(item.fields.categories)
                    ? item.fields.categories.map((cat) => cat.trim())
                    : [],
                  link: item.fields.link || "",
                };
              }
              return null;
            })
            .filter((project) => project !== null);

          setProjects(formattedProjects);

          const allCategories = ["All"];
          portfolioResponse.items.forEach((item) => {
            if (item.fields.categories) {
              item.fields.categories.forEach((cat) => {
                const trimmedCat = cat.trim();
                if (!allCategories.includes(trimmedCat)) {
                  allCategories.push(trimmedCat);
                }
              });
            }
          });

          // Sort categories based on predefined order
          const sortedCategories = predefinedCategoryOrder.filter((cat) =>
            allCategories.includes(cat)
          );

          setCategories(sortedCategories);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data from Contentful:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  const filteredProjects =
    activeCategory === "All"
      ? projects
      : projects.filter((project) => project.category.includes(activeCategory));

  // Sorting projects by category order
  const categoryIndex = (category) =>
    predefinedCategoryOrder.indexOf(category) !== -1
      ? predefinedCategoryOrder.indexOf(category)
      : predefinedCategoryOrder.length;

  const sortedProjects = [...filteredProjects].sort((a, b) => {
    const categoryA = a.category.length > 0 ? a.category[0] : "";
    const categoryB = b.category.length > 0 ? b.category[0] : "";
    return categoryIndex(categoryA) - categoryIndex(categoryB);
  });

  return (
    <div className="wgl-portfolio_wrapper container mx-auto px-4 sm:px-6 lg:px-8">
      <div className="wgl-portfolio_header mb-6 flex flex-col md:flex-row justify-between p-2">
        <div className="item_title">
          <h3 className="portfolio_title text-3xl sm:text-4xl font-bold mt-3 mb-3 text-[#232323]">
            Glimpse of our work
          </h3>
        </div>
        <div className="w-full overflow-x-auto whitespace-nowrap md:flex md:flex-nowrap space-x-2 scrollbar-hide">
          {categories.map((category) => {
            const projectCount =
              category === "All"
                ? projects.length
                : projects.filter((project) =>
                    project.category.includes(category)
                  ).length;

            return (
              <button
                key={category}
                className={`px-3 py-2 rounded transition font-bold ${
                  activeCategory === category ? "text-[#ec008c]" : "text-black"
                }`}
                onClick={() => setActiveCategory(category)}
              >
                {category} ({projectCount})
              </button>
            );
          })}
        </div>
      </div>

      <div className="container">
        <Box>
          <Masonry
            breakpointCols={{
              default: 3,
              1024: 2,
              640: 1,
            }}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {loading ? (
              <div>Loading...</div>
            ) : (
              sortedProjects.map((project) => (
                <React.Fragment key={project.id}>
                  <div className="hover relative w-auto">
                    <Link
                      to={`/portfolio/${project.title
                        .replace(/\s+/g, "-")
                        .toLowerCase()}`}
                      className="block"
                    >
                      <img
                        srcSet={`${project.image}`}
                        src={`${project.image}`}
                        alt={project.title}
                        loading="lazy"
                        style={{ display: "block", width: "100%" }}
                      />
                      <div className="item__description">
                        <div className="text-white text-center">
                          <div className="post_cats">
                            {project.category.map((cat) => (
                              <span key={cat} className="portfolio-category">
                                {cat}
                              </span>
                            ))}
                          </div>
                          <h5 className="title">{project.title}</h5>
                        </div>
                      </div>
                    </Link>
                  </div>
                </React.Fragment>
              ))
            )}
          </Masonry>
        </Box>
      </div>
    </div>
  );
};

export default PortfolioMasonry;
